export enum GameState {
	GAME = 'GAME',
	GAME_END = 'GAME_END',
	GAME_SCORE_SAVED = 'GAME_SCORE_SAVED',
	PENDING = 'PENDING',
	PREROLL = 'PREROLL',
	COVER = 'COVER',
	ADBLOCK = 'ADBLOCK',
	REWARD = 'REWARD',
	INTERSTITIAL = 'INTERSTITIAL',
	PREROLL_PLAYING = 'PREROLL_PLAYING',
}
