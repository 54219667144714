import { GameModelFromFeed } from '@/root/app/[locale]/games/[slug]';
import { Endpoints, fetchData, gameOptions, strapiOptions } from './data-fetcher';
import { ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { notFound } from 'next/navigation';
import { logger } from '@/shared/utils/logger';

export type TFeedFetchParams = {
	feedId: number | null;
	lang: string;
};
type TFeed = ({ feedId, lang }: TFeedFetchParams) => Promise<GameModelFromFeed[]>;
type TConfig = (id: number) => Promise<ConfigGamePage>;

export const fetchGameFeed: TFeed = async ({ feedId, lang }) => {
	if (!feedId) {
		logger.debug('feedId is null');
		return notFound();
	}
	const url = Endpoints.getGameFeed(feedId, lang);

	return fetchData(url, gameOptions);
};
export const fetchGamePageConfig: TConfig = async (id: number) => {
	const url = Endpoints.getGamePageConfig(id);
	return fetchData(url, strapiOptions);
};
