'use client';

import React, { createContext, useContext, ReactNode } from 'react';
import { IBrowser, IDevice } from 'ua-parser-js';

export enum DeviceType {
	DESKTOP = 'pc',
	TABLET = 'tablet',
	MOBILE = 'mobile',
}

interface UserAgentContextType {
	device: IDevice;
	browser: IBrowser;
	detectDevice: () => DeviceType;
	isNotPc: () => boolean;
	isDesktop: () => boolean;
	isTablet: () => boolean;
	isMobile: () => boolean;
	detectBrowser: () => IBrowser;
	isPad: () => boolean;
	isIOS: () => boolean;
}

const UserAgentContext = createContext<UserAgentContextType | undefined>(undefined);

export const UserAgentProvider: React.FC<{
	device: IDevice;
	browser: IBrowser;
	children: ReactNode;
}> = ({ device, browser, children }) => {
	const detectDevice = (): DeviceType => {
		if (device.type === 'mobile') {
			return DeviceType.MOBILE;
		}
		if (isPad() || device.type === 'tablet' || device.vendor === 'Kindle') {
			return DeviceType.TABLET;
		}
		return DeviceType.DESKTOP;
	};

	const isNotPc = () => [DeviceType.TABLET, DeviceType.MOBILE].includes(detectDevice());
	const isDesktop = () => detectDevice() === DeviceType.DESKTOP;
	const isTablet = () => detectDevice() === DeviceType.TABLET;
	const isMobile = () => detectDevice() === DeviceType.MOBILE;
	const detectBrowser = () => browser;
	const isPad = () => device.vendor === 'Apple' && device.model === 'iPad';
	const isIOS = () => device.vendor === 'Apple' && ['iPhone', 'iPad', 'iPod'].includes(device.model || '');

	const value: UserAgentContextType = {
		device,
		browser,
		detectDevice,
		isNotPc,
		isDesktop,
		isTablet,
		isMobile,
		detectBrowser,
		isPad,
		isIOS,
	};

	return <UserAgentContext.Provider value={value}>{children}</UserAgentContext.Provider>;
};

export const useDeviceDetector = () => {
	const context = useContext(UserAgentContext);
	if (context === undefined) {
		throw new Error('useDeviceDetector must be used within a UserAgentProvider');
	}
	return context;
};
