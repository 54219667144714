export enum CUSTOM_BEHAVIOUR_REGIONS {
	//USA, TODO: what about California with their CCPA?
	US = 'US',
	AS = 'AS', //American Samoa
	GU = 'GU', //Guam
	MP = 'MP', //Northern Mariana Islands
	PR = 'PR', //Puerto Rico
	UM = 'UM', //U.S. Minor Outlying Islands
	VI = 'VI', //U.S. Virgin Islands

	//EEA included outermost regions / GDPR
	AT = 'AT', //Austria
	AX = 'AX', //Åland Islands
	BE = 'BE', //Belgiuutilsm
	BG = 'BG', //Bulgaria
	CY = 'CY', //Cyprus
	CZ = 'CZ', //Czech Republic
	DE = 'DE', //Germany
	DK = 'DK', //Denmark
	EE = 'EE', //Estonia
	EL = 'EL', //Greece
	ES = 'ES', //Spain
	EU = 'EU', //European Union
	FI = 'FI', //Finland
	FR = 'FR', //France
	GB = 'GB', //United Kingdom (Great Britain)
	GF = 'GF', //French Guiana
	GI = 'GI', //Gibraltar
	GP = 'GP', //Guadeloupe
	GR = 'GR', //Greece
	HR = 'HR', //Croatia
	HU = 'HU', //Hungary
	IE = 'IE', //Ireland, Republic of (EIRE)
	IS = 'IS', //Iceland
	IT = 'IT', //Italy
	LI = 'LI', //Liechtenstein
	LT = 'LT', //Lithuania
	LU = 'LU', //Luxembourg
	LV = 'LV', //Latvia
	ME = 'ME', //Montenegro
	MF = 'MF', //Saint Martin
	MQ = 'MQ', //Martinique
	MT = 'MT', //Malta
	NL = 'NL', //Netherlands
	NO = 'NO', //Norway
	PL = 'PL', //Poland
	PT = 'PT', //Portugal
	RE = 'RE', //Réunion
	RO = 'RO', //Romania
	SE = 'SE', //Sweden
	SI = 'SI', //Slovenia
	SK = 'SK', //Slovakia
	YT = 'YT', //Mayotte
	UK = 'UK', //United Kingdom (Great Britain)

	CA = 'CA', //Canada
	BR = 'BR', //Brazil

	//No consent regions, disabling ads and analytics
	//China / PIPL
	CN = 'CN', //China
	HK = 'HK', //Hong Kong
	MO = 'MO', //Macao
}
